*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  font-size: 14px !important;
  font-family: 'Roboto', sans-serif;
  background-color: rgba(0,0,0,0.8);
}
.scroll::-webkit-scrollbar {
  width: 10px;
}
.scroll::-webkit-scrollbar-track {
  background: black;
}
.scroll::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 6px;
  border: 2px solid black;
}
.w-100{
  width: 100% !important;
}
.btn {
  border-radius: 0.5rem !important;
  padding: 0.5rem 1.5rem;
}
.header{
  text-align: center;
}
.logo{
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  padding: 1rem;
}
.label {
  color: rgba(255,255,255,0.5);
  font-size: 0.8rem;
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}
textarea{
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-color: rgba(255,255,255,0.2) !important; 
  color: white !important;
  font-size: 0.8rem !important;
  width: 100%;
  border-radius: 0.5rem;
}
input[type=range]{
  width: 100% !important;
}
input[type=file]{
  color: white;
}
.dark-card{
  border: 1px solid rgba(255,255,255,0.2);
  border-radius: 0.5rem;
  padding: 0.8rem;
  margin-bottom: 1rem;
  width: 100%;
}
.preview-container {
  width: 100%;
  height: 100vh;
  background-color: black;
}
.preview{
  font-family: 'Fira Code', monospace;
  padding: 10vh 10vw;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: 50vh;
  background-position: 50% 50%;
  background-clip: text;
  -webkit-background-clip: text;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  cursor: default;
  user-select: none;
  word-break: break-all;
}
.sidebar{
  display: block;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  bottom: 0;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  background-color: #13161A;
  padding: 1rem;
  max-width: 80%;
  overflow-y: auto;
}
.hide{
  margin-left: auto;
  margin-bottom: 1rem;
  display: block;
  width: 30px;
  height: 30px;
  font-size: 20px;
  border: 0;
  border-radius: 40px;
  outline: none;
  background-color: rgba(255,255,255,0.5);
  color: black;
}
.hide:hover, .hide:focus{
  background-color: rgba(255,255,255,0.9);;
}
.rounded{
  border-radius: 1rem !important;
}
.bg-black{
  background-color: black !important;
}